import { IconsNames } from 'components/atoms';

export enum FooterCategoryNames {
  programming = 'Программирование',
  testing = 'Тестирование',
  design = 'Дизайн',
  // management = 'Менеджмент',
  analysis = 'Аналитика',
  hr = 'HR',
}

export enum CategoryNames {
  all = 'Все курсы',
  hits = 'Хиты продаж',

  programming = 'Программирование',
  // management = 'Менеджмент',
  analysis = 'Аналитика',
  design = 'Дизайн',
  testing = 'Тестирование',
  gameDev = 'GameDev',
  hr = 'HR',
}

export enum Courses {
  data_engineer = 'data_engineer',
  frontend = 'frontend',
  uxui = 'uxui',
  python = 'python',
  java = 'java',
  manual_qa = 'manual_qa',
  business_analyst = 'ba',
  devops = 'dev-ops',
  ios = 'ios',
  android = 'android',
  csharp = 'csharp',
  unity = 'unity',
  unreal_engine = 'unreal_engine',
  game_design = 'gamedesigner',
  web = 'web',
  qa_csharp = 'qa_csharp',
  qa_java = 'qa_java',
  qa_python = 'qa_python',
  qa_js = 'qa_js',
  project_manager = 'pm',
  business_intelligence = 'bi',
  game_dev = 'game_dev',
  graphic_designer = 'graphic_designer',
  motion = 'motion',
  data_science = 'data_science',
  hr = 'hr',
  system_analyst = 'system_analyst',
  go = 'go',
  one_c = 'one_c',
  cyber = 'cyber',
  machine_learning = 'machine_learning',
  react_native = 'react_native',
  product_manager = 'product_manager',
  product_analyst = 'product_analyst',
}

export interface ICourse {
  key: Courses;
  categoryName: CategoryNames;
  courseName: string;
  discountPrice: string;
  oldPrice: string;
  img: IconsNames;
  link: string;
}
export const HitCourses: Courses[] = [Courses.frontend, Courses.python, Courses.business_analyst, Courses.uxui];

export const AllCourses: ICourse[] = [
  // {
  //   key: Courses.data_engineer,
  //   categoryName: CategoryNames.programming,
  //   courseName: 'Инженер данных',
  //   discountPrice: '3 930 ₽',
  //   oldPrice: '7 150 ₽',
  //   img: IconsNames.cs_data_engineer,
  //   link: 'https://teachmeskills.ru/kursy-programmirovaniya/data-engineer-online',
  // },
  {
    key: Courses.frontend,
    categoryName: CategoryNames.programming,
    courseName: 'Front End разработчик',
    discountPrice: '3 060 BYN',
    oldPrice: '3 600 BYN',
    img: IconsNames.cs_fe,
    link: 'https://teachmeskills.by/kursy-programmirovaniya/frontend-html-css-javascript-online',
  },
  {
    key: Courses.uxui,
    categoryName: CategoryNames.design,
    courseName: 'UX/UI дизайнер',
    discountPrice: '2 304 BYN',
    oldPrice: '2 700 BYN',
    img: IconsNames.cs_uxui,
    link: 'https://teachmeskills.by/kursy-programmirovaniya/ux_ui_online',
  },
  {
    key: Courses.python,
    categoryName: CategoryNames.programming,
    courseName: 'Python разработчик',
    discountPrice: '2 808 BYN',
    oldPrice: '3 000 BYN',
    img: IconsNames.cs_python,
    link: 'https://teachmeskills.by/kursy/obuchenie-python-online',
  },
  {
    key: Courses.java,
    categoryName: CategoryNames.programming,
    courseName: 'Java разработчик',
    discountPrice: '3 060 BYN',
    oldPrice: '3 600 BYN',
    img: IconsNames.cs_java,
    link: 'https://teachmeskills.by/kursy-programmirovaniya/obuchenie-java-online',
  },
  {
    key: Courses.manual_qa,
    categoryName: CategoryNames.testing,
    courseName: 'Тестирование веб и мобильных приложений',
    discountPrice: '1 836 BYN',
    oldPrice: '2 150 BYN',
    img: IconsNames.cs_manual_qa,
    link: 'https://teachmeskills.by/kursy-programmirovaniya/qa-manual-testirovanie-web-online',
  },
  {
    key: Courses.business_analyst,
    categoryName: CategoryNames.analysis,
    courseName: 'Бизнес-аналитик в IT',
    discountPrice: '2 736 BYN',
    oldPrice: '3 200 BYN',
    img: IconsNames.cs_ba,
    link: 'https://teachmeskills.by/kursy-programmirovaniya/business-analyst-online',
  },
  // {
  //   key: Courses.devops,
  //   categoryName: CategoryNames.programming,
  //   courseName: 'DevOps инженер',
  //   discountPrice: '3 930 ₽',
  //   oldPrice: '7 150 ₽',
  //   img: IconsNames.cs_devops,
  //   link: 'https://teachmeskills.ru/kursy-programmirovaniya/kursy-dev-ops-online',
  // },
  {
    key: Courses.ios,
    categoryName: CategoryNames.programming,
    courseName: 'iOS разработчик',
    discountPrice: '2 898 BYN',
    oldPrice: '3 400 BYN',
    img: IconsNames.cs_ios,
    link: 'https://teachmeskills.by/kursy-programmirovaniya/ios-swift-razrabotka-online',
  },
  {
    key: Courses.android,
    categoryName: CategoryNames.programming,
    courseName: 'Android разработчик',
    discountPrice: '2 556 BYN',
    oldPrice: '3 000 BYN',
    img: IconsNames.cs_android,
    link: 'https://teachmeskills.by/kursy-programmirovaniya/android-razrabotka-online',
  },
  {
    key: Courses.csharp,
    categoryName: CategoryNames.programming,
    courseName: 'C# (.NET) разработчик',
    discountPrice: '2 808 BYN',
    oldPrice: '3 300 BYN',
    img: IconsNames.cs_csharp,
    link: 'https://teachmeskills.by/kursy-programmirovaniya/kursy-asp-net-online',
  },
  {
    key: Courses.unity,
    categoryName: CategoryNames.gameDev,
    courseName: 'Разработчик игр на Unity',
    discountPrice: '2 736 BYN',
    oldPrice: '3 200 BYN',
    img: IconsNames.cs_unity,
    link: 'https://teachmeskills.by/kursy-programmirovaniya/unity-game-developer-online',
  },
  // {
  //   key: Courses.unreal_engine,
  //   categoryName: CategoryNames.gameDev,
  //   courseName: 'Разработчик игр на Unreal Engine 5',
  //   discountPrice: '3 930 ₽',
  //   oldPrice: '7 150 ₽',
  //   img: IconsNames.cs_unreal_engine,
  //   link: 'https://teachmeskills.ru/kursy-programmirovaniya/unreal-engine-developer-online',
  // },
  {
    key: Courses.game_design,
    categoryName: CategoryNames.gameDev,
    courseName: 'Геймдизайн',
    discountPrice: '1 962 BYN',
    oldPrice: '2 300 BYN',
    img: IconsNames.cs_gamedesign,
    link: 'https://teachmeskills.by/kursy-programmirovaniya/game-designer-online',
  },
  {
    key: Courses.web,
    categoryName: CategoryNames.programming,
    courseName: 'WEB разработчик',
    discountPrice: '2 898 BYN',
    oldPrice: '3 400 BYN',
    img: IconsNames.cs_web,
    link: 'https://teachmeskills.by/kursy-programmirovaniya/web-developer-online',
  },
  {
    key: Courses.qa_csharp,
    categoryName: CategoryNames.testing,
    courseName: 'Автоматизированное тестирование на C#',
    discountPrice: '2 304 BYN',
    oldPrice: '2 700 BYN',
    img: IconsNames.cs_qa_csharp,
    link: 'https://teachmeskills.by/kursy-programmirovaniya/qa-avtomatizirovannoe-testirovanie-na-c-sharp-online',
  },
  // {
  //   key: Courses.qa_java,
  //   categoryName: CategoryNames.testing,
  //   courseName: 'Автоматизированное тестирование на Java',
  //   discountPrice: '3 930 ₽',
  //   oldPrice: '7 150 ₽',
  //   img: IconsNames.cs_qa_java,
  //   link: 'https://teachmeskills.ru/kursy-programmirovaniya/qa-avtomatizirovannoe-testirovanie-java-online',
  // },
  // {
  //   key: Courses.qa_python,
  //   categoryName: CategoryNames.testing,
  //   courseName: 'Автоматизированное тестирование на Python',
  //   discountPrice: '3 930 ₽',
  //   oldPrice: '7 150 ₽',
  //   img: IconsNames.cs_qa_python,
  //   link: 'https://teachmeskills.ru/kursy-programmirovaniya/qa-avtomatizirovannoe-testirovanie-na-python-online',
  // },
  // {
  //   key: Courses.qa_js,
  //   categoryName: CategoryNames.testing,
  //   courseName: 'Автоматизированное тестирование на JavaScript',
  //   discountPrice: '3 930 ₽',
  //   oldPrice: '7 150 ₽',
  //   img: IconsNames.cs_qa_js,
  //   link: 'https://teachmeskills.ru/kursy-programmirovaniya/qa-avtomatizirovannoe-testirovanie-javascript-online',
  // },
  // {
  //   key: Courses.project_manager,
  //   categoryName: CategoryNames.management,
  //   courseName: 'Project Manager в IT',
  //   discountPrice: '3 930 ₽',
  //   oldPrice: '7 150 ₽',
  //   img: IconsNames.cs_project,
  //   link: 'https://teachmeskills.ru/kursy-programmirovaniya/project-manager-online',
  // },
  // {
  //   key: Courses.business_intelligence,
  //   categoryName: CategoryNames.programming,
  //   courseName: 'Business intelligence (BI) разработчик',
  //   discountPrice: '3 930 ₽',
  //   oldPrice: '7 150 ₽',
  //   img: IconsNames.cs_bi,
  //   link: 'https://teachmeskills.ru/kursy-programmirovaniya/business-intelligence-online',
  // },
  // {
  //   key: Courses.game_dev,
  //   categoryName: CategoryNames.gameDev,
  //   courseName: '3D моделирование в GameDev',
  //   discountPrice: '3 930 ₽',
  //   oldPrice: '7 150 ₽',
  //   img: IconsNames.cs_3d,
  //   link: 'https://teachmeskills.ru/kursy-programmirovaniya/3d-modeling-gamedev-online',
  // },
  // {
  //   key: Courses.graphic_designer,
  //   categoryName: CategoryNames.design,
  //   courseName: 'Графический дизайнер',
  //   discountPrice: '3 930 ₽',
  //   oldPrice: '7 150 ₽',
  //   img: IconsNames.cs_designer,
  //   link: 'https://teachmeskills.ru/kursy-programmirovaniya/graphicheskyi-designer-online',
  // },
  {
    key: Courses.motion,
    categoryName: CategoryNames.design,
    courseName: 'Motion дизайнер',
    discountPrice: '2 898 BYN',
    oldPrice: '3 400 BYN',
    img: IconsNames.cs_motion,
    link: 'https://teachmeskills.by/kursy-programmirovaniya/motion-designer-online',
  },
  // {
  //   key: Courses.data_science,
  //   categoryName: CategoryNames.programming,
  //   courseName: 'Data Scientist',
  //   discountPrice: '3 930 ₽',
  //   oldPrice: '7 150 ₽',
  //   img: IconsNames.cs_ds,
  //   link: 'https://teachmeskills.ru/kursy-programmirovaniya/data-scientist-online',
  // },
  {
    key: Courses.hr,
    categoryName: CategoryNames.hr,
    courseName: 'HR-Generalist',
    discountPrice: '1 368 BYN',
    oldPrice: '1 600 BYN',
    img: IconsNames.cs_hr,
    link: 'https://teachmeskills.by/kursy-programmirovaniya/hr-generalist-online',
  },
  {
    key: Courses.system_analyst,
    categoryName: CategoryNames.analysis,
    courseName: 'Системный аналитик',
    discountPrice: '1 116 BYN',
    oldPrice: '1 300 BYN',
    img: IconsNames.cs_system,
    link: 'https://teachmeskills.by/kursy/systems-analyst-online',
  },
  // {
  //   key: Courses.go,
  //   categoryName: CategoryNames.programming,
  //   courseName: 'Go разработчик',
  //   discountPrice: '3 930 ₽',
  //   oldPrice: '7 150 ₽',
  //   img: IconsNames.cs_go,
  //   link: 'https://teachmeskills.ru/kursy-programmirovaniya/qa-go-deveoper-online',
  // },
  // {
  //   key: Courses.one_c,
  //   categoryName: CategoryNames.programming,
  //   courseName: '1С разработчик',
  //   discountPrice: '3 930 ₽',
  //   oldPrice: '7 150 ₽',
  //   img: IconsNames.cs_one_c,
  //   link: 'https://teachmeskills.ru/kursy-programmirovaniya/odin-c-razrabotchik-online',
  // },
  {
    key: Courses.cyber,
    categoryName: CategoryNames.programming,
    courseName: 'Кибербезопасность',
    discountPrice: '2 736 BYN',
    oldPrice: '3 200 BYN',
    img: IconsNames.cs_cyber,
    link: 'https://teachmeskills.by/kursy-programmirovaniya/cybersecurity-online',
  },
  {
    key: Courses.machine_learning,
    categoryName: CategoryNames.programming,
    courseName: 'Machine Learning',
    discountPrice: '3 402 BYN',
    oldPrice: '4 000 BYN',
    img: IconsNames.cs_machine_learning,
    link: 'https://teachmeskills.by/kursy/machine-learning-engineer',
  },
  // {
  //   key: Courses.react_native,
  //   categoryName: CategoryNames.programming,
  //   courseName: 'React Native разработчик',
  //   discountPrice: '3 930 ₽',
  //   oldPrice: '7 150 ₽',
  //   img: IconsNames.cs_react,
  //   link: 'https://teachmeskills.ru/kursy-programmirovaniya/react-native-developer',
  // },
  // {
  //   key: Courses.product_manager,
  //   categoryName: CategoryNames.management,
  //   courseName: 'Product Manager в IT',
  //   discountPrice: '3 930 ₽',
  //   oldPrice: '7 150 ₽',
  //   img: IconsNames.cs_pdm,
  //   link: 'https://teachmeskills.ru/kursy-programmirovaniya/product-manager-online',
  // },
  {
    key: Courses.product_analyst,
    categoryName: CategoryNames.analysis,
    courseName: 'Продуктовый аналитик в IT',
    discountPrice: '2 484 BYN',
    oldPrice: '2 920 BYN',
    img: IconsNames.cs_pa,
    link: 'https://teachmeskills.by/kursy-programmirovaniya/product-analyst-online',
  },
];
